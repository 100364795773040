import { NgModule } from '@angular/core';
import { AccountService } from './account/account.services';
import { CepService } from './util/cep.service';
import { MelhorEnvioService } from './util/melhor.envio.service';
import { ScriptService } from './util/script.service';
import { AssetsService } from './account/asset.services';
import { ImageService } from './util/image.service';
import { ErrorHandlerService } from './util/error.handler.service';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { PackagesService } from './account/packages.service';
import { OnkeypressService } from './util/onkeypress.service';
import { FormatValueService } from './util/format-value.service';
import { FeaturesStatusService } from './util/features-status.service';
import { MetamaskService } from './util/metamask.service';
import { DocumentValidationService } from './util/cpfCnpjValidate.service';
import { ConfigReaderService } from './util/config.reader.service';
import { NftConversionService } from './util/nft.conversion.service'
import { PaginatorTranslationService } from './util/paginator-translation.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { UXConfigsService } from './util/ux-configs.service';
import { CollectionsService } from './account/collections.service';
import { FinancialService } from './financial/financial';
import { ShareLinkService } from './util/share-link.service';
import { DepositsService } from './account/deposits.services';
import { FileService } from './util/file.service';
import { ReloadSidenavBalance } from './util/reload.sidenav.balance.service';
import { CheckoutService } from './cart/checkout.service';
import { FormatStringService } from './util/format-string.service';
import { PaymentConfigsService } from './util/payment-configs.service';
import { HandleDateService } from './util/handle-date.service';
import { ExchangeService } from './exchange/exchange.services';
import { CategoriesService } from './marketplace/categories.service';
import { ProductsService } from './marketplace/product.services';
import { PricesService } from './marketplace/prices/prices.service';
import { OrderService } from './marketplace/store/order.service';
import { StoreService } from './marketplace/store/store.service';
import { LetterOfCreditService } from './marketplace/letter-of-credit/letter-of-credit.service';
import { NFTStakingService } from './NFT/nft.staking.services';
import { ReloadUserData } from './util/reload.user.data.service';
import { CorporateEscrowService } from './financial/corporate.escrow';
import { FeatureGuardService } from 'src/app/guards/feature-guard.service';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { SidenavService } from './util/sidenav.service';
import { BankingService } from './account/banking.services';
import { HomeService } from './account/home.service';
import { ShoppingCartService } from './marketplace/cart/shopping-cart.service';
import { FlexibleCharacteristicsService } from './marketplace/flexible-characteristics/flexible-characteristics.service';
import { TokensService } from './tokens/token.service';
import { TranslationConstants } from './util/translation.constants';
import { ChatService } from './chat/chat.service';
import { CalculateRemainingAuctionTimeService } from './util/calculate-remaining-auction-time.service';
import { NationalityService } from './util/nationality.service';
import { SnackbarService } from './util/snackbar.service';

@NgModule({
    providers: [
        AccountService,
        PackagesService,
        MelhorEnvioService,
        ScriptService,
        CollectionsService,
        CheckoutService,
        ConfigReaderService,
        FeaturesStatusService,
        TokensService,
        NFTStakingService,
        CepService,
        BankingService,
        ShoppingCartService,
        CollectionsService,
        FileService,
        AssetsService,
        LetterOfCreditService,
        FlexibleCharacteristicsService,
        PricesService,
        CategoriesService,
        StoreService,
        MetamaskService,
        DepositsService,
        CollectionsService,
        ReloadUserData,
        CorporateEscrowService,
        ImageService,
        ErrorHandlerService,
        CurrencyPipe,
        DecimalPipe,
        FeatureGuardService,
        AuthGuardService,
        OnkeypressService,
        SidenavService,
        FormatValueService,
        PaymentConfigsService,
        FeaturesStatusService,
        DocumentValidationService,
        ConfigReaderService,
        NftConversionService,
        UXConfigsService,
        FinancialService,
        ShareLinkService,
        ReloadSidenavBalance,
        FormatStringService,
        ExchangeService,
        CategoriesService,
        TranslationConstants,
        ProductsService,
        PricesService,
        OrderService,
        StoreService,
        LetterOfCreditService,
        ChatService,
        CalculateRemainingAuctionTimeService,
        NationalityService,
        {
            provide: MatPaginatorIntl,
            useClass: PaginatorTranslationService
        },
        HandleDateService,
        SnackbarService
    ]
})
export class ServicesModule { }