export const environment = {
    apiBaseUrl: 'API_BASE_URL',
    pageTitle: 'Axia',
    isCapacitor:false,
    mockExchange: false,
    tokenIdWall: 'U2FsdGVkX18MvEtUxdhTaTJ/RUI+2opGnrgGlS8X2KXrQ9W4TQ==',
    projectName: 'Axia',
    firstRoute: '/home',
    graphUrl: 'https://dillianz-site.herokuapp.com/dlz_graph',
    showGraph: false,
    pixelId: '',
    BankingCheckout: true,
    baseUrl: 'axiadigitalsolutions.net',
    showAccountImage: false,
    isKrakenIntegrationEnabled: false,
    signupExternal: false,
    collectionId: 'e40b9792-0b78-4a1a-b2a5-45c61e659667',
    tokenExchange: 'USDT',
    signupURL: '',
    WS_ENDPOINT: "wss://exi.axiadigitalsolutions.net",
    decimalPlaces: '1.6-6',
    oneSignalId: '5e617fe4-df3f-47c7-a21f-6301adb806ca',
    decimalsPlacesBought: 3,
    mainRoute: 'account/login',
    showHeaderBuy: true,
    companyName: 'Axia Digital Solutions',
    cnpj: '37.702.652/0001-88',
    billNameCompany:"Axia Digital",
    waterMark: false,
    webService: 'ws://54.144.94.179:5000'
};